define("discourse/plugins/discourse-rss-polling/discourse/controllers/admin-plugins-rss-polling", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/utils", "@ember-decorators/object", "discourse-common/utils/decorators", "discourse/plugins/discourse-rss-polling/admin/models/rss-polling-feed-settings"], function (_exports, _controller, _object, _computed, _utils, _object2, _decorators, _rssPollingFeedSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsRssPollingController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "feedSettings", [(0, _computed.alias)("model")]))();
    #feedSettings = (() => (dt7948.i(this, "feedSettings"), void 0))();
    saving = false;
    valid = false;
    unsavable(valid, saving) {
      return !valid || saving;
    }

    // TODO: extract feed setting into its own component && more validation
    static #_2 = (() => dt7948.n(this.prototype, "unsavable", [(0, _decorators.default)("valid", "saving")]))();
    validate() {
      let overallValidity = true;
      this.get("feedSettings").forEach(feedSetting => {
        const localValidity = !(0, _utils.isBlank)(feedSetting.feed_url) && !(0, _utils.isBlank)(feedSetting.author_username);
        (0, _object.set)(feedSetting, "valid", localValidity);
        overallValidity = overallValidity && localValidity;
      });
      this.set("valid", overallValidity);
    }
    static #_3 = (() => dt7948.n(this.prototype, "validate", [(0, _object2.observes)("feedSettings.@each.{feed_url,author_username}")]))();
    create() {
      this.get("feedSettings").addObject({
        feed_url: null,
        author_username: null,
        discourse_category_id: null,
        discourse_tags: null,
        feed_category_filter: null
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "create", [_object.action]))();
    destroyFeedSetting(feedSetting) {
      this.get("feedSettings").removeObject(feedSetting);
      this.send("update");
    }
    static #_5 = (() => dt7948.n(this.prototype, "destroyFeedSetting", [_object.action]))();
    update() {
      this.set("saving", true);
      _rssPollingFeedSettings.default.update(this.get("feedSettings")).then(updatedSettings => {
        this.set("feedSettings", updatedSettings["feed_settings"]);
      }).finally(() => {
        this.set("saving", false);
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "update", [_object.action]))();
    updateAuthorUsername(setting, selected) {
      (0, _object.set)(setting, "author_username", selected.firstObject);
    }
    static #_7 = (() => dt7948.n(this.prototype, "updateAuthorUsername", [_object.action]))();
  }
  _exports.default = AdminPluginsRssPollingController;
});